import R from "ramda";

function initCart(data) {
  return {
    items: initCartItems(data.cart),
    subtotal: data.net_subtotal,
    icannFee: data.icann_fee,
    discounts: data.discounts || [],
    partner: data.partner,
    primaryItem: data.primary_item,
    canEnterPromoCode: data.can_enter_promo_code,
    total: data.total,
    exploded: data.exploded
  };
}

function initCartItems(items) {
  return items.map((item, n) => ({
    name: item.name,
    price: item.net_price,
    components: initComponents(item.components),
    position: n + 1
  }));
}

const initComponents = R.compose(R.map(fixAmount), markInstantSavings);

function fixAmount(c) {
  return R.merge(c, { amount: c.net_amount || c.amount });
}

function markInstantSavings(components) {
  const END = {};
  const pairs = R.zip(components, R.append(END, R.tail(components)));
  const markComponent = ([c1, c2]) => {
    if (c2.id === "instant_savings") {
      c1.instant_savings = c2.amount;
    }
    return c1;
  };

  return R.map(markComponent, pairs);
}

export default initCart;
