import h from "h";
import CartItem from "./CartItem";

function CartItems({ cart, loading, actions }) {
  const props = {
    loading,
    actions,
    strict: cart.strictValidation || false,
    instantSavingsPromo: cart.instantSavingsPromo
  };
  const showPrimaryToggle = cart.primaryItem !== null && cart.items.length > 1;
  const eachItem = (item, index) => {
    const key = item.name;
    const isPrimary = index === cart.primaryItem;
    const partner = cart.partner;
    return h(CartItem, {
      key,
      item,
      partner,
      isPrimary,
      showPrimaryToggle,
      ...props
    });
  };
  return h(".items", cart.items.map(eachItem));
}

export default CartItems;
