import React, { useReducer } from "react";
import R from "ramda";
import c from "classnames";
import { h, pluralize, fasIcon, numberToCurrency, fromHTML } from "@/h";

import { Box, BoxItem } from "../../elements/box";
import Tooltip from "../form/Tooltip";
import { TieredPriceInfoToolTip } from "../form/Tooltip";
import AuthCode from "./AuthCodeForm";
import styles from "./cart_item_view.module.sass";
import NumberSelector from "./NumberSelector";

function cartItemTitle(item) {
  const premiumType = item.components[0].premium_type;
  const className = cartItemTitleClass(premiumType);
  return <span className={c("cart_item_name", className)}>{item.name}</span>;
}

function cartItemTitleClass(premiumType) {
  switch (premiumType) {
    case "registry":
      return "tiered_price";
    case "premium":
    case "aftermarket":
      return "premium";
    default:
      return "";
  }
}

function NewCartItemView(props) {
  const { item, loading, strict } = props;
  const { instantSavingsPromo, isPrimary, showPrimaryToggle, partner, disabled } = props;
  const { emailHighlight, actions } = props;

  const itemType = item.components[0].description;
  const regType = item.components[0].reg_type;
  const componentType = item.components[0].type;

  const items1 = headItems();
  if (items1 == null) {
    /* eslint-disable no-console */
    console.error("Can't build components for", itemType);
    return <div></div>;
  }

  const items2 = emailItems();

  const boxProps = {
    key: `${item.name}-cart-item`,
    className: c({ loading: loading(item.name) }),
    title: cartItemTitle(item),
    onClose: () => actions.removeCartItem(item),
  };

  const item3 = <WhoisPrivacy item={props.item} actions={props.actions} />;

  return buildBox();

  function buildBox() {
    if(componentType === "subscription") {
      return (
        <Box {...boxProps}>
          {items1}
        </Box>
      );
    }
    return (
      <Box {...boxProps}>
        {items1}
        {items2}
        {item3}
      </Box>
    );
  }

  function headItems() {
    switch (itemType) {
      case "registration":
        return RegistrationComponents({
          item,
          showPrimaryToggle,
          isPrimary,
          partner,
          actions,
        });
      case "personal name":
        return SurnameComponents({ item, actions });
      case "renewal":
        return RenewalComponents({ item, actions });
      case "transfer":
        return TransferComponents({ item, strict, actions });
      case "upgrade":
        return MailboxUpgradeComponents({ item, actions });
      case "email":
        return [];
      case "account_funding":
        return [AccountBalanceComponent({ item })];
      case "Realnames Email":
        return SubscriptionRealnamesComponent({item, actions});
      default:
        return null;
    }
  }

  function emailItems() {
    if (itemType === "personal name") {
      return [personalNameEmailComponents()];
    } else if (itemType === "renewal" && regType === "surname") {
      return [readOnlyPersonalNameComponents()];
    } else if (itemType === "upgrade" || itemType === "account_funding") {
      return [];
    }

    let promoMessage = null,
      toggleButton = null;

    if (itemType === "registration" && isPrimary) {
      const className = c("toggle", "highlight-before");
      toggleButton = (
        <BoxItem className={className}>
          <span className="show_email">
            {fasIcon("envelope-o")}
            <span className="sc">
              {`One email forward has been added to this domain (required for ${partner})`}
            </span>
          </span>
        </BoxItem>
      );
    }

    const mailboxes =
      itemType === "personal name"
        ? personalNameEmailComponents()
        : mailboxComponents();

    return (
      <React.Fragment>
        {promoMessage}
        {proratedMessage(item)}
        {toggleButton}
        {mailboxes}
      </React.Fragment>
    );
  }

  function highlightType(n, len) {
    if (!emailHighlight) {
      return;
    } else if (n == 0) {
      return "highlight highlight-first";
    } else if (n == len - 1) {
      return "highlight highlight-last";
    } else {
      return "highlight";
    }
  }

  function mailboxComponents() {
    const cs = R.filter(
      R.propSatisfies((id) => isEmailComponentId(id), "id"),
      item.components
    );
    if (cs.length == 0) {
      return null;
    }
    const eachComponent = (component, idx) => {
      const highlight = highlightType(idx, cs.length);
      const key =
        component.id === "email"
          ? `${item.name}-${component.mailbox_type}`
          : `${item.name}-${component.id}-${idx}`;
      if (isPrimary && component.num_emails === 0) {
        return null;
      } else if (isPrimary) {
        return (
          <MailboxComponent
            key={key}
            item={item}
            highlight={highlight}
            component={component}
            actions={actions}
            disabled={true}
          />
        );
      } else {
        return (
          <MailboxComponent
            key={key}
            item={item}
            highlight={highlight}
            component={component}
            actions={actions}
          />
        );
      }
    };
    return (
      <div className={styles.mailbox}>
        <p>
          Email Options{" "}
          <a className={styles.compare_link} href="/email">
            Compare
          </a>
        </p>
        {cs.map(eachComponent)}
      </div>
    );
  }

  function isEmailComponentId(id) {
    return id == "email" || id == "mailbox_purchase" || id == "instant_savings";
  }

  function personalNameEmailComponents() {
    return <PersonalEmailComponent item={item} actions={actions} />;
  }

  function readOnlyPersonalNameComponents() {
    return (
      <PersonalEmailComponent item={item} actions={actions} disabled={disabled} />
    );
  }
}

function RegistrationComponents(props) {
  const { item, showPrimaryToggle, actions } = props;
  const label = registrationLabel(item);

  switch (item.components[0].type) {
    case "registry":
    case "premium":
      return (
        <React.Fragment>
          <PremiumTermLine item={item} actions={actions} label={label} />
          {showPrimaryToggle && h(PrimaryToggleLine, props)}
        </React.Fragment>
      );
    default:
      return (
        <React.Fragment>
          <TermLine item={item} actions={actions} label={label} />
          {showPrimaryToggle && h(PrimaryToggleLine, props)}
        </React.Fragment>
      );
  }

  function registrationLabel(item) {
    const component = item.components[0];
    switch (component.type) {
      case "premium":
        return premiumRegistrationLabel(component);
      default:
        return <span className="item-type">Domain Registration</span>;
    }
  }

  function premiumRegistrationLabel(component) {
    const { premium_type } = component;
    switch (premium_type) {
      case "registry":
        return (
          <div>
            <div className="item-type">Tiered Price Domain Registration</div>
            <div className={styles.note}>
              <span className={c("tiered_price_star", styles.star)}>
                {fasIcon("star")}
              </span>
              {`Annual renewal is ${numberToCurrency(component.renew_amount)}.`}
            </div>
          </div>
        );
      default:
        return (
          <div>
            <div className="item-type">Premium Domain Registration</div>
            <div className={styles.note}>
              <span className={c("premium_star", styles.star)}>
                {fasIcon("star")}
              </span>
              {`Annual renewal is ${numberToCurrency(component.renew_amount)}.`}
              {premium_type == "aftermarket" && (
                <p>
                  This is an aftermarket premium domain purchase. The domain is
                  listed for sale by the current owner. <br/>While the majority of
                  aftermarket sales complete successfully and within 7 days, transfer
                  of the domain to your Hover account is subject to additional checks
                  and is not guaranteed.
                </p>
              )}
            </div>
          </div>
        );
    }
  }
}

function SurnameComponents({ item, actions }) {
  if (item.components[0].reg_type == "opensrs") {
    return RegistrationComponents({ item, actions });
  } else {
    return (
      <TermLine
        item={item}
        actions={actions}
        label={"Personal Email Registration"}
      />
    );
  }
}

function SubscriptionRealnamesComponent({ item, actions }) {
  return (
    <SubscriptionTermLine
      item={item}
      actions={actions}
      label={item.components[0].description}
    />
  )
}

function RenewalComponents({ item, actions }) {
  const label = renewalLabel(item);
  return <TermLine item={item} actions={actions} label={label} />;
}

function renewalLabel(item) {
  if (item.components[0].premium_type == "registry") {
    return (
      <span>
        Tiered Price Domain Renewal
        <TieredPriceInfoToolTip />
      </span>
    );
  } else {
    return "Domain Renewal";
  }
}

function MailboxUpgradeComponents({ item }) {
  const label = "Upgrade to Big Mailbox";
  return (
    <React.Fragment>
      {proratedMessage(item)}
      <BoxItem className="term">
        <div className="label">{label}</div>
        <div className="amount">{item.components[0].amount}</div>
      </BoxItem>
    </React.Fragment>
  );
}

function AccountBalanceComponent({ item }) {
  return (
    <BoxItem>
      <div className="label">Credit</div>
      <div className="amount">{item.price}</div>
    </BoxItem>
  );
}

function proratedMessage(item) {
  const proratedComponent = R.find(R.prop("prorated"), item.components);
  if (proratedComponent) {
    return <BoxItem className="prorated">{proratedComponent.prorated}</BoxItem>;
  }
}

function TransferComponents({ item, strict, actions }) {
  return (
    <React.Fragment>
      <TermLine item={item} actions={actions} label={transferInLabel(item)} />
      <AuthCode item={item} actions={actions} strict={strict} />
    </React.Fragment>
  );
}

function transferInLabel(item) {
  const component = item.components[0];
  if (component.premium_type == "registry") {
    const renewalPrice = numberToCurrency(component.renew_amount);
    return (
      <span>
        Tiered Price Transfer In
        <TieredPriceInfoToolTip />
        <span className="note">{`Annual renewal is ${renewalPrice}.`}</span>
      </span>
    );
  } else {
    return "Transfer In";
  }
}

function MailboxComponent({ item, actions, highlight, component, disabled }) {
  if (component.id == "instant_savings") {
    return InstantSavingsComponent({ item, component });
  }

  const numEmails = component.num_emails;

  const minEmails = component.minimum || 0;
  const maxEmails = 10;

  const controls = (
    <NumberSelector
      changeHandler={(n) => actions.changeMailboxes(item, component, n)}
      className={styles.control}
      disabled={disabled}
      max={maxEmails}
      min={minEmails}
      value={numEmails}
    />
  );

  const className = styles.mailbox_item;
  return (
    <div
      className={className}
      data-test-id={`${item.name}-${component.mailbox_type}`}
    >
      <div className={styles.label}>
        <p>
          {component.heading}
          <span
            className={c("bold", styles.cost)}
          >{`$${component.unit_amount} / yr`}</span>
        </p>
        <p className={styles.tagline}>{component.tagline}</p>
      </div>
      <div className={styles.quantity}>
        {controls}
        <div className={styles.amount}>{blankIfZero(component.amount)}</div>
      </div>
    </div>
  );
}

function blankIfZero(amount) {
  if (amount === "$0.00") return "";
  else return amount;
}

function InstantSavingsComponent({ component }) {
  return (
    <div className={c("instant_savings", styles.instant_savings)}>
      <div className={styles.instant_savings_label}>Instant Savings</div>
      <div className={styles.instant_savings_amount}>{component.amount}</div>
    </div>
  );
}

function PersonalEmailComponent({ item, actions, disabled = false }) {
  const component = item.components[0] || {};
  const numEmails = component.num_emails;
  const minEmails = component.minimum || 0;
  const maxEmails = 10;
  const emailComponent = item.components[item.components.length - 1];

  const controls = (
    <NumberSelector
      changeHandler={(n) => actions.changePersonalMailboxes(item, n)}
      className={styles.control}
      disabled={disabled}
      max={maxEmails}
      min={minEmails}
      value={numEmails}
    />
  );

  const className = styles.mailbox_item;
  return (
    <div
      className={className}
      data-test-id={`${item.name}-${component.mailbox_type}`}
    >
      <div className={styles.label}>
        <p>
          {component.heading}
          <span
            className={c("bold", styles.cost)}
          >{`$${component.unit_amount} / yr`}</span>
        </p>
        <p className={styles.tagline}>{component.tagline}</p>
      </div>
      <div className={styles.quantity}>
        {controls}
        <div className={styles.amount}>{blankIfZero(component.amount)}</div>
      </div>
    </div>
  );
}

function TermLine({ item, actions, label }) {
  const component = item.components[0];
  const { term, term_options } = component;

  const minTerm = Math.min(...term_options);
  const maxTerm = Math.max(...term_options);

  return (
    <div className={c("term_line", styles.term_line)}>
      <div className={c("label", styles.term_label)}>{label}</div>
      <div className={styles.term_right}>
        <div className="term">
          <NumberSelector
            changeHandler={(n) => actions.changeTerm(item, n)}
            max={maxTerm}
            min={minTerm}
            options={term_options}
            text="year"
            value={term}
          />
        </div>
        <div className={c("bold", "amount", styles.amount)}>
          {item.components[0].amount}
        </div>
      </div>
    </div>
  );
}

// Using `selected` on option tag triggers warning but setting value on root select element
// as suggested in the docs did not work: https://reactjs.org/docs/forms.html#the-select-tag
function SubscriptionTermLine({ item, actions, label }) {
  const component = item.components[0];
  const { term, term_options } = component;
  const current_option = term_options.find(to => to.plan_id === term);
  return (
    <div className={c("term_line", styles.term_line, styles.subscription_term_line)}>
      <div className={c("label", styles.term_label)}>{label}</div>
      <div className={styles.term_right}>
        <div className="term">
          {current_option.display_option}
        </div>
        <div className={c("bold", "amount", styles.amount)}>
          {item.components[0].amount}
        </div>
      </div>
    </div>
  )
}

function PremiumTermLine({ item, actions, label }) {
  return (
    <div className={c("term_line", styles.term_line)}>
      <div className={c("label", styles.term_label)}>{label}</div>
      <div className={styles.term_right}>
        <div className="term"></div>
        <div className={c("bold", "amount", styles.amount)}>
          {item.components[0].amount}
        </div>
      </div>
    </div>
  );
}

function Select({ options, name, value, readOnly, onChange }) {
  const optionElements = options.map(({ label, value }) =>
    h("option", { value }, label)
  );
  const select = h("select", { name, value, onChange, readOnly }, optionElements);
  return h(".selectbox", select);
}

class WhoisPrivacy extends React.Component {
  render() {
    const { item, actions } = this.props;

    const { whois_privacy_unsupported } = item.components[0];
    if (whois_privacy_unsupported) {
      return (
        <BoxItem className="whois">
          <div className="label">{whois_privacy_unsupported}</div>
          <div className="amount bold">N/A</div>
        </BoxItem>
      );
    }

    const tip = (
      <Tooltip
        id={item.name + "whois"}
        tooltip={
          "WHOIS Privacy keeps your contact information protected from the public WHOIS database."
        }
        onShow={() => actions.track("show_whois_tooltip", item.name)}
      />
    );

    return (
      <BoxItem className="whois">
        <div className="label">
          {"WHOIS Privacy"}
          {tip}
        </div>
        <div className="amount bold">FREE</div>
      </BoxItem>
    );
  }
}

function onOffSlide(htmlId, onChange, checked) {
  const className = c("onoff_slide", checked ? "is_on" : "is_off");
  return (
    <div className={className}>
      <input type="checkbox" onChange={onChange} checked={checked} id={htmlId} />
      <label htmlFor={htmlId} />
    </div>
  );
}

function PrimaryToggleLine({ item, isPrimary, partner, actions }) {
  const checked = isPrimary;
  const onChange = (e) => actions.changeIsPrimary(item, e.target.checked);
  const htmlId = `isPrimary${item.position}`;
  return (
    <BoxItem className="primary-item-toggle">
      <div className="label">{`Use this domain for ${partner}`}</div>
      <div className="toggle">{onOffSlide(htmlId, onChange, checked)}</div>
      <div className="amount"></div>
    </BoxItem>
  );
}

export default NewCartItemView;
