import { createStore, compose } from "redux";

import rootReducer from "../reducers/index";
import middleware from "../middleware/index";

export default function configureStore(initialState) {
  const store = createStore(
    rootReducer,
    initialState,
    compose(
      middleware,
      window.devToolsExtension ? window.devToolsExtension() : f => f
    )
  );

  // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
  if (module.hot) {
    module.hot.accept("../reducers/index", () =>
      store.replaceReducer(require("../reducers/index").default)
    );
  }

  return store;
}
