import h from "h";
import { TextField, SelectField, CheckboxField } from "../form";
import { countries } from "common/countries";

export default function TldQuestion({ param, field }) {
  switch (param.type) {
    case "ca_legal_type":
    case "select": {
      const opts = param.options.map(([label, value]) => ({
        value,
        label
      }));
      if (!param.required) {
        opts.unshift({ value: null, label: "Select one..." });
      }
      return h(SelectField, {
        width: 12,
        label: param.label,
        field,
        options: opts
      });
    }

    case "country":
      return h(SelectField, {
        width: 12,
        label: param.label,
        field,
        options: countries
      });

    case "eco_agreeTerms":
      return ecoTLDQuestion(field, param);

    case "app_agreeTerms":
    case "page_agreeTerms":
    case "dev_agreeTerms":
    case "day_agreeTerms":
      const tld = param.type.match(/(.*)_agreeTerms/)[1];
      return encryptedTLDQuestion(tld, field, param);

    case "text":
    case "ca_registrant_name":
    default:
      return h(TextField, {
        width: 12,
        label: param.label,
        field
      });
  }
}

function ecoTLDQuestion(field, param) {
  return h(`.eco-terms`, [
    h("p", [
      "After you register your .eco domain, you'll receive an email from the ",
      ".eco registry inviting you to create a .eco profile and to take a ",
      "simple pledge to show your commitment to environmental action and affirm ",
      "eligibility by sharing your eco actions and commitments. ",
      "This is required before you can start using your .eco domain."
    ]),
    h("div", { style: { marginTop: "1rem" } }, [
      h(CheckboxField, {
        width: 12,
        label: param.label,
        field
      })
    ])
  ]);
}

function encryptedTLDQuestion(tld, field, param) {
  return h(`.${tld}-terms`, [
    h("p", [
      `Registration of .${tld} domains is open to anyone. `,
      `You should be aware that .${tld} is an encrypted-by-default TLD `,
      "by virtue of being inscribed in the HSTS Preload list ",
      "found in all modern web browsers. ",
      `Websites hosted on .${tld} will not load `,
      "unless they are served over HTTPS ",
      "(i.e. have a valid SSL certificate installed)."
    ]),
    h("div", { style: { marginTop: "1rem" } }, [
      h(CheckboxField, {
        width: 12,
        label: param.label,
        field
      })
    ])
  ]);
}
